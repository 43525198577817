<!-- HTML -->
<template>
    <!-- Container -->
    <div>
    </div>
</template>
<!-- Javascript -->
<script>
    // Import des modules
    // Déclaration des constantes et des variables
    // Export du module
    export default {
        components: {
        },
        computed: {
        },
        created() {
        },
        data() {
            return {
            };
        },
        destroyed: function() {
        },
        methods: {
        },
        mounted() {
        },
        name: 'editor',
        watch: {
        },
    }
</script>
<!-- CSS -->
<style scoped>
</style>
<template>
<!-- Structure -->
<div class="container-news">
    <!-- Barre d'outils -->
    <v-toolbar class="mb-5" v-if="user_auth">
        <v-badge class="hidden-xs-only" color="green darken-3" :content="items.length" offset-y="15" :value="items.length">
            <v-toolbar-title> 
                {{ screen_category }} :  {{ grid_width }} + ' / ' + {{ grid_rows }}
            </v-toolbar-title>
        </v-badge>
        <v-spacer class="hidden-xs-only"></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="green darken-3"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :value="false"
                    @click="grid({'action': 'del row'})">
                        <v-icon color="green darken-3">mdi-table-row-remove</v-icon>
                </v-btn>
            </template>
            <span>Supprimer une ligne</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="green darken-3"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :value="false"
                    @click="grid({'action': 'add row'})">
                        <v-icon color="green darken-3">mdi-table-row-plus-after</v-icon>
                </v-btn>
            </template>
            <span>Ajouter une ligne</span>
        </v-tooltip>
        <v-switch
              color="green darken-3"
              inset
              hide-details
              v-model="grid_edit"
        ></v-switch>
    </v-toolbar>
    <!-- News -->
    <div class="grid-container" :style="grid_css_props">
        <div class="grid">
            <div class="grid-cell" :key="n" :style="grid({'action': 'css cell', 'param': n})" v-show="grid_edit" v-for="n in grid_cells"></div>
            <div class="grid-item">
                <div class="news">
                    <div class="news-img"></div>
                    <div class="news-title"></div>
                    <div class="news-txt">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tellus nunc, dapibus mollis purus id, accumsan vehicula ex. Suspendisse eleifend lacinia felis eu finibus. Donec malesuada vitae est id laoreet. In efficitur diam ac quam semper dictum. Sed iaculis odio a ipsum porta, ac cursus magna convallis. Donec luctus risus quis purus ultrices accumsan. Etiam vitae ex velit. Suspendisse faucibus, diam vel tincidunt rutrum, metus orci elementum mi, quis ultricies erat justo in lorem. Praesent ullamcorper lacus ut vulputate tincidunt. Integer accumsan ipsum vitae purus bibendum consequat. Aenean consequat nisi non dapibus finibus. Aenean scelerisque accumsan ligula a porttitor. Aenean ac sagittis nisi, sit amet vestibulum tellus. Duis porta ex libero, a pulvinar urna pulvinar non. Vivamus ac diam vitae urna ultricies maximus. Mauris varius tincidunt rutrum.

Aenean laoreet, velit porta aliquam laoreet, arcu mi convallis velit, sit amet aliquam odio lacus ac metus. Nulla laoreet, est sed varius faucibus, lacus tellus convallis augue, sed laoreet tellus elit eu ex. Suspendisse blandit aliquam rutrum. Praesent tellus metus, blandit ullamcorper enim in, elementum convallis nisl. Aliquam eleifend nibh ac lorem condimentum, a feugiat augue varius. Fusce sit amet tincidunt felis. Vestibulum tincidunt leo sit amet semper suscipit. Praesent posuere vestibulum pulvinar. Sed commodo orci at tortor tempor ultricies.
                    </div>
                </div>
                <v-card class="green--text text--darken-3" light>
                    <v-img
                        src="../assets/img/ludo1.jpg"
                        aspect-ratio="1.8">
                    </v-img>
                    <v-card-title class="headline">Ludothèque</v-card-title>
                    <v-card-text class="text-justify">
                        <editor/>
                    </v-card-text>
                </v-card>
                <div class="grid-item-actions">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="green darken-3"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                :value="false"
                                @click="grid({'action': 'add col'})">
                                    <v-icon color="green darken-3">mdi-table-column-plus-after</v-icon>
                            </v-btn>
                        </template>
                        <span>Ajouter une colonne</span>
                    </v-tooltip>
                </div>
            </div>
            <div class="grid-item-2">
                
            </div>
        </div>
    </div>
</div>
</template>
<!-- Script -->
<script>
// Import des modules
import editor from '../components/editor';
import { mapActions } from 'vuex';
import user_service from "../services/user.service";
// Déclaration des constantes et des variables
// Export du composant
export default {
    components: {
        editor,
    },
    computed: {
        grid_cells() {
            var number = this.grid_columns*this.grid_rows;
            return number;
        },
        grid_columns() {
            if (this.screen_category === 'smartphone' || this.screen_category === 'smartphone landscape') {
                return 1;
            } 
            if (this.screen_category === 'tablet' || this.screen_category === 'tablet landscape') {
                return 2;
            }
            if (this.screen_category === 'desktop' || this.screen_category === 'large') {
                return 3;
            }
            // var columns = Math.floor(this.grid_container_width.value / (this.grid_columns_width.value + this.grid_column_gap));
        },
        grid_columns_width() {
            if (this.screen_category === 'smartphone') {
                return {value: 100, unit: '%'};
            }
            if (this.screen_category === 'smartphone landscape') {
                return {value: 372, unit: 'px'};
            } 
            if (this.screen_category === 'tablet' || this.screen_category === 'tablet landscape' || this.screen_category === 'desktop' || this.screen_category === 'large') {
                return {value: this.grid_column_width, unit: 'px'};
            }
        },
        grid_container_width() {
            return {value: this.screen_width - 24, unit: 'px'};
        },
        grid_css_props() {
            return {
                '--grid-container-background-color': this.grid_edit ? '#484848' : 'transparent',
                '--grid-column-gap': this.grid_column_gap + 'px',
                '--grid-height': this.grid_height.value + this.grid_height.unit,
                '--grid-item-bg-color': this.grid_edit ? 'orange' : 'transparent',
                '--grid-item-border': this.grid_edit ? '5px solid darkorange' : 'none',
                '--grid-item-padding': this.grid_edit ? '10px' : 0,
                '--grid-row-gap': this.grid_row_gap + 'px',
                '--grid-template-columns': 'repeat(' + this.grid_columns + ', ' + this.grid_column_size + ')',
                '--grid-template-rows': 'repeat(' + this.grid_rows + ',' + this.grid_row_size + ')',
                '--grid-width': this.grid_width.value + this.grid_width.unit,
            }
        },
        grid_height() {
            if (this.screen_category === 'smartphone' || this.screen_category === 'smartphone landscape') {
                return {value: 'auto', unit: ''};
            } else {
                return {value: (this.grid_row_height + this.grid_row_gap) * this.grid_rows - this.grid_row_gap, unit: 'px'};
            }
        },
        grid_rows() {
            return Math.floor((this.items.length - 1) / this.grid_columns) + 1; 
        },
        grid_width() {
            if (this.screen_category === 'smartphone') {
                return {value: 100, unit: '%'};
            }
            if (this.screen_category === 'smartphone landscape') {
                return {value: this.grid_columns_width.value, unit: 'px'};
            } 
            if (this.screen_category === 'tablet' || this.screen_category === 'tablet landscape' || this.screen_category === 'desktop' || this.screen_category === 'large') {
                return {value: (this.grid_columns_width.value + this.grid_column_gap) * this.grid_columns - this.grid_column_gap, unit: 'px'};
            }
        },
        screen_category() {
            return this.$store.state.sc;
        },
        screen_width() {
            return this.$store.state.sw;
        },
        user_current() {
            return this.$store.state.auth.user;
        },
        user_auth() {
            const roles_auth = [
                'admin',
                'blog',
                'webmaster'
            ];
            var auth = false;
            if (this.user_current) {
                for (let role of roles_auth) {
                    if (this.user_current.role === role) {
                        auth = true;
                    }
                }
            }
            return auth;
        },
    },
    data() {
        return {
            grid_column_gap: 15,
            grid_column_size: '1fr',
            grid_column_width: 372,
            grid_edit: false,
            grid_row: 5,
            grid_row_height: 381,
            grid_row_max: 6,
            grid_row_min: 1,
            grid_row_gap: 15,
            grid_row_size: '1fr',
            items: [
                {'title': 'Ludothèque', 'text': 'LoremIpsum', 'img': ''},
                {'title': 'Ludothèque 2', 'text': 'LoremIpsum', 'img': ''}
            ],
            items_edit: false,
            loading: true,
            notification: '',
        }
    },
    methods: {
        grid(action) {
            var a = action.action ;
            var p = action.param ;
            if (a === "add row") {
                this.items.push({'title': 'Ludothèque 2', 'text': 'LoremIpsum', 'img': ''})
            }
            if (a === "css cell") {
                var row = Math.floor((p - 1) / this.grid_columns) +1 ;
                var col = ((p) % this.grid_columns === 0) ? this.grid_columns : (p) % this.grid_columns ;
                var style = {'grid-row': row, 'grid-column': col};
                console.log(style);
                return style;
            }
            if (a === "del row") {
                if (this.grid_row > this.grid_row_min) {
                    this.grid_row -= 1;
                }
            }
        },
        initialize() {
            this.loading=true;
            user_service.get_all('news').then(response => {
                if (!response.data.message) {
                    //this.items = response.data;
                    this.loading = false;
                } else {
                    this.notification = response.data.message;
                    this.notify({'message': this.notification, 'type': 'success'});
                }
            },
            error => {
                if(error.response.data.error){
                    this.notification = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                        this.notify({'message': this.notification, 'type': 'error'});
                } else {
                    this.notification = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                        this.notify({'message': this.notification, 'type': 'warning'});
                }
            });
        },
        ...mapActions(['notify']),
    },
    mounted() {
        this.initialize();
    },
    name: 'home2',      
    watch: {
    }
}
</script>
<style scoped>
.container-news {
    margin: 0 12px 0 12px;
}
.grid {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    grid-template-rows: var(--grid-template-rows);
    grid-column-gap: var(--grid-column-gap);
    grid-row-gap: var(--grid-row-gap);
    height: var(--grid-height);
    width: var(--grid-width);
}
.grid-cell {
    background-color: #636363;
    z-index:1;
}
.grid-container {
    background-color: var(--grid-container-background-color);
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 0;
}
.grid-item {
    background-color: var(--grid-item-bg-color);
    border: var(--grid-item-border);
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    grid-area: 1 / 1 / 2 / 2;
    justify-content: space-between;
    opacity: 0.8;
    overflow: hidden;
    padding: var(--grid-item-padding);
    z-index: 2;
}
.grid-item-2{
    border: 1px solid blue;
    grid-area: 1 / 1 / 2 / 2;
    z-index: 3;
}
.grid-item-actions {
    border: 1px solid red;
}
.news {
    border: 1px solid green;
    display: flex;
    flex-flow: column;
    overflow: hidden;
}
.news-img {

}
.news-title {

}
.news-txt {

}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-news"},[(_vm.user_auth)?_c('v-toolbar',{staticClass:"mb-5"},[_c('v-badge',{staticClass:"hidden-xs-only",attrs:{"color":"green darken-3","content":_vm.items.length,"offset-y":"15","value":_vm.items.length}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.screen_category)+" : "+_vm._s(_vm.grid_width)+" + ' / ' + "+_vm._s(_vm.grid_rows)+" ")])],1),_c('v-spacer',{staticClass:"hidden-xs-only"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green darken-3","icon":"","value":false},on:{"click":function($event){return _vm.grid({'action': 'del row'})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-3"}},[_vm._v("mdi-table-row-remove")])],1)]}}],null,false,2065017363)},[_c('span',[_vm._v("Supprimer une ligne")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green darken-3","icon":"","value":false},on:{"click":function($event){return _vm.grid({'action': 'add row'})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-3"}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,false,1275373962)},[_c('span',[_vm._v("Ajouter une ligne")])]),_c('v-switch',{attrs:{"color":"green darken-3","inset":"","hide-details":""},model:{value:(_vm.grid_edit),callback:function ($$v) {_vm.grid_edit=$$v},expression:"grid_edit"}})],1):_vm._e(),_c('div',{staticClass:"grid-container",style:(_vm.grid_css_props)},[_c('div',{staticClass:"grid"},[_vm._l((_vm.grid_cells),function(n){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.grid_edit),expression:"grid_edit"}],key:n,staticClass:"grid-cell",style:(_vm.grid({'action': 'css cell', 'param': n}))})}),_c('div',{staticClass:"grid-item"},[_vm._m(0),_c('v-card',{staticClass:"green--text text--darken-3",attrs:{"light":""}},[_c('v-img',{attrs:{"src":require("../assets/img/ludo1.jpg"),"aspect-ratio":"1.8"}}),_c('v-card-title',{staticClass:"headline"},[_vm._v("Ludothèque")]),_c('v-card-text',{staticClass:"text-justify"},[_c('editor')],1)],1),_c('div',{staticClass:"grid-item-actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green darken-3","icon":"","value":false},on:{"click":function($event){return _vm.grid({'action': 'add col'})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-3"}},[_vm._v("mdi-table-column-plus-after")])],1)]}}])},[_c('span',[_vm._v("Ajouter une colonne")])])],1)],1),_c('div',{staticClass:"grid-item-2"})],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news"},[_c('div',{staticClass:"news-img"}),_c('div',{staticClass:"news-title"}),_c('div',{staticClass:"news-txt"},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tellus nunc, dapibus mollis purus id, accumsan vehicula ex. Suspendisse eleifend lacinia felis eu finibus. Donec malesuada vitae est id laoreet. In efficitur diam ac quam semper dictum. Sed iaculis odio a ipsum porta, ac cursus magna convallis. Donec luctus risus quis purus ultrices accumsan. Etiam vitae ex velit. Suspendisse faucibus, diam vel tincidunt rutrum, metus orci elementum mi, quis ultricies erat justo in lorem. Praesent ullamcorper lacus ut vulputate tincidunt. Integer accumsan ipsum vitae purus bibendum consequat. Aenean consequat nisi non dapibus finibus. Aenean scelerisque accumsan ligula a porttitor. Aenean ac sagittis nisi, sit amet vestibulum tellus. Duis porta ex libero, a pulvinar urna pulvinar non. Vivamus ac diam vitae urna ultricies maximus. Mauris varius tincidunt rutrum. Aenean laoreet, velit porta aliquam laoreet, arcu mi convallis velit, sit amet aliquam odio lacus ac metus. Nulla laoreet, est sed varius faucibus, lacus tellus convallis augue, sed laoreet tellus elit eu ex. Suspendisse blandit aliquam rutrum. Praesent tellus metus, blandit ullamcorper enim in, elementum convallis nisl. Aliquam eleifend nibh ac lorem condimentum, a feugiat augue varius. Fusce sit amet tincidunt felis. Vestibulum tincidunt leo sit amet semper suscipit. Praesent posuere vestibulum pulvinar. Sed commodo orci at tortor tempor ultricies. ")])])}]

export { render, staticRenderFns }